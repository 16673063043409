// source: pub-gamecenter/private/console.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pub$gamecenter_public_v1_resource_pb = require('../../pub-gamecenter/public/v1/resource_pb.js');
goog.object.extend(proto, pub$gamecenter_public_v1_resource_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
goog.exportSymbol('proto.gamecenter.private.DeleteGameRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.DeleteGameResponse', null, global);
goog.exportSymbol('proto.gamecenter.private.GetGameBasicInfoRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.GetGameBasicInfoResponse', null, global);
goog.exportSymbol('proto.gamecenter.private.GetGameConfigRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.GetGameConfigResponse', null, global);
goog.exportSymbol('proto.gamecenter.private.ListGameBasicInfoRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.ListGameBasicInfoResponse', null, global);
goog.exportSymbol('proto.gamecenter.private.UpsertGameBasicInfoRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.UpsertGameBasicInfoResponse', null, global);
goog.exportSymbol('proto.gamecenter.private.UpsertGameConfigRequest', null, global);
goog.exportSymbol('proto.gamecenter.private.UpsertGameConfigResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.ListGameBasicInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.ListGameBasicInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.ListGameBasicInfoRequest.displayName = 'proto.gamecenter.private.ListGameBasicInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.ListGameBasicInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gamecenter.private.ListGameBasicInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.gamecenter.private.ListGameBasicInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.ListGameBasicInfoResponse.displayName = 'proto.gamecenter.private.ListGameBasicInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.GetGameBasicInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.GetGameBasicInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.GetGameBasicInfoRequest.displayName = 'proto.gamecenter.private.GetGameBasicInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.GetGameBasicInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.GetGameBasicInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.GetGameBasicInfoResponse.displayName = 'proto.gamecenter.private.GetGameBasicInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.UpsertGameBasicInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.UpsertGameBasicInfoRequest.displayName = 'proto.gamecenter.private.UpsertGameBasicInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.UpsertGameBasicInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.UpsertGameBasicInfoResponse.displayName = 'proto.gamecenter.private.UpsertGameBasicInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.GetGameConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.GetGameConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.GetGameConfigRequest.displayName = 'proto.gamecenter.private.GetGameConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.GetGameConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.GetGameConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.GetGameConfigResponse.displayName = 'proto.gamecenter.private.GetGameConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.UpsertGameConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.UpsertGameConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.UpsertGameConfigRequest.displayName = 'proto.gamecenter.private.UpsertGameConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.UpsertGameConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.UpsertGameConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.UpsertGameConfigResponse.displayName = 'proto.gamecenter.private.UpsertGameConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.DeleteGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.DeleteGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.DeleteGameRequest.displayName = 'proto.gamecenter.private.DeleteGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.private.DeleteGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.private.DeleteGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.private.DeleteGameResponse.displayName = 'proto.gamecenter.private.DeleteGameResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.ListGameBasicInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.ListGameBasicInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.ListGameBasicInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.ListGameBasicInfoRequest}
 */
proto.gamecenter.private.ListGameBasicInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.ListGameBasicInfoRequest;
  return proto.gamecenter.private.ListGameBasicInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.ListGameBasicInfoRequest}
 */
proto.gamecenter.private.ListGameBasicInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.ListGameBasicInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.ListGameBasicInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.ListGameBasicInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gamecenter.private.ListGameBasicInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.ListGameBasicInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.ListGameBasicInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.ListGameBasicInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    pub$gamecenter_public_v1_resource_pb.GameBasicInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.ListGameBasicInfoResponse}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.ListGameBasicInfoResponse;
  return proto.gamecenter.private.ListGameBasicInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.ListGameBasicInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.ListGameBasicInfoResponse}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameBasicInfo;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameBasicInfo.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.ListGameBasicInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.ListGameBasicInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.ListGameBasicInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated gamecenter.public.v1.GameBasicInfo games = 1;
 * @return {!Array<!proto.gamecenter.public.v1.GameBasicInfo>}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.gamecenter.public.v1.GameBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameBasicInfo, 1));
};


/**
 * @param {!Array<!proto.gamecenter.public.v1.GameBasicInfo>} value
 * @return {!proto.gamecenter.private.ListGameBasicInfoResponse} returns this
*/
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.gamecenter.public.v1.GameBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.gamecenter.public.v1.GameBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.gamecenter.private.ListGameBasicInfoResponse} returns this
 */
proto.gamecenter.private.ListGameBasicInfoResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.GetGameBasicInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.GetGameBasicInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameBasicInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.GetGameBasicInfoRequest}
 */
proto.gamecenter.private.GetGameBasicInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.GetGameBasicInfoRequest;
  return proto.gamecenter.private.GetGameBasicInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.GetGameBasicInfoRequest}
 */
proto.gamecenter.private.GetGameBasicInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.GetGameBasicInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.GetGameBasicInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameBasicInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.gamecenter.private.GetGameBasicInfoRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.GetGameBasicInfoRequest} returns this
 */
proto.gamecenter.private.GetGameBasicInfoRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.GetGameBasicInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.GetGameBasicInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameBasicInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    game: (f = msg.getGame()) && pub$gamecenter_public_v1_resource_pb.GameBasicInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.GetGameBasicInfoResponse}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.GetGameBasicInfoResponse;
  return proto.gamecenter.private.GetGameBasicInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.GetGameBasicInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.GetGameBasicInfoResponse}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameBasicInfo;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameBasicInfo.deserializeBinaryFromReader);
      msg.setGame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.GetGameBasicInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.GetGameBasicInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameBasicInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGame();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional gamecenter.public.v1.GameBasicInfo game = 1;
 * @return {?proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.getGame = function() {
  return /** @type{?proto.gamecenter.public.v1.GameBasicInfo} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameBasicInfo, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.GameBasicInfo|undefined} value
 * @return {!proto.gamecenter.private.GetGameBasicInfoResponse} returns this
*/
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.setGame = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.GetGameBasicInfoResponse} returns this
 */
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.clearGame = function() {
  return this.setGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.GetGameBasicInfoResponse.prototype.hasGame = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.UpsertGameBasicInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    game: (f = msg.getGame()) && pub$gamecenter_public_v1_resource_pb.GameBasicInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoRequest}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.UpsertGameBasicInfoRequest;
  return proto.gamecenter.private.UpsertGameBasicInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoRequest}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameBasicInfo;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameBasicInfo.deserializeBinaryFromReader);
      msg.setGame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.UpsertGameBasicInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGame();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional gamecenter.public.v1.GameBasicInfo game = 1;
 * @return {?proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.getGame = function() {
  return /** @type{?proto.gamecenter.public.v1.GameBasicInfo} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameBasicInfo, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.GameBasicInfo|undefined} value
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoRequest} returns this
*/
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.setGame = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoRequest} returns this
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.clearGame = function() {
  return this.setGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.UpsertGameBasicInfoRequest.prototype.hasGame = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.UpsertGameBasicInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    game: (f = msg.getGame()) && pub$gamecenter_public_v1_resource_pb.GameBasicInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoResponse}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.UpsertGameBasicInfoResponse;
  return proto.gamecenter.private.UpsertGameBasicInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoResponse}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameBasicInfo;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameBasicInfo.deserializeBinaryFromReader);
      msg.setGame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.UpsertGameBasicInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGame();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional gamecenter.public.v1.GameBasicInfo game = 1;
 * @return {?proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.getGame = function() {
  return /** @type{?proto.gamecenter.public.v1.GameBasicInfo} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameBasicInfo, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.GameBasicInfo|undefined} value
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoResponse} returns this
*/
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.setGame = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.UpsertGameBasicInfoResponse} returns this
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.clearGame = function() {
  return this.setGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.UpsertGameBasicInfoResponse.prototype.hasGame = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.GetGameConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.GetGameConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    env: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.GetGameConfigRequest}
 */
proto.gamecenter.private.GetGameConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.GetGameConfigRequest;
  return proto.gamecenter.private.GetGameConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.GetGameConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.GetGameConfigRequest}
 */
proto.gamecenter.private.GetGameConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnv(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.GetGameConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.GetGameConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnv();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string env = 1;
 * @return {string}
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.getEnv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.GetGameConfigRequest} returns this
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.setEnv = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_code = 2;
 * @return {string}
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.GetGameConfigRequest} returns this
 */
proto.gamecenter.private.GetGameConfigRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.GetGameConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.GetGameConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.GetGameConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameConfig: (f = msg.getGameConfig()) && pub$gamecenter_public_v1_resource_pb.GameConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.GetGameConfigResponse}
 */
proto.gamecenter.private.GetGameConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.GetGameConfigResponse;
  return proto.gamecenter.private.GetGameConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.GetGameConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.GetGameConfigResponse}
 */
proto.gamecenter.private.GetGameConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameConfig;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameConfig.deserializeBinaryFromReader);
      msg.setGameConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.GetGameConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.GetGameConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.GetGameConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.GetGameConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional gamecenter.public.v1.GameConfig game_config = 1;
 * @return {?proto.gamecenter.public.v1.GameConfig}
 */
proto.gamecenter.private.GetGameConfigResponse.prototype.getGameConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GameConfig} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameConfig, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.GameConfig|undefined} value
 * @return {!proto.gamecenter.private.GetGameConfigResponse} returns this
*/
proto.gamecenter.private.GetGameConfigResponse.prototype.setGameConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.GetGameConfigResponse} returns this
 */
proto.gamecenter.private.GetGameConfigResponse.prototype.clearGameConfig = function() {
  return this.setGameConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.GetGameConfigResponse.prototype.hasGameConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.UpsertGameConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.UpsertGameConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    env: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gameConfig: (f = msg.getGameConfig()) && pub$gamecenter_public_v1_resource_pb.GameConfig.toObject(includeInstance, f),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest}
 */
proto.gamecenter.private.UpsertGameConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.UpsertGameConfigRequest;
  return proto.gamecenter.private.UpsertGameConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.UpsertGameConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest}
 */
proto.gamecenter.private.UpsertGameConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnv(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 3:
      var value = new pub$gamecenter_public_v1_resource_pb.GameConfig;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameConfig.deserializeBinaryFromReader);
      msg.setGameConfig(value);
      break;
    case 4:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.UpsertGameConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.UpsertGameConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnv();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGameConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pub$gamecenter_public_v1_resource_pb.GameConfig.serializeBinaryToWriter
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string env = 1;
 * @return {string}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.getEnv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.setEnv = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_code = 2;
 * @return {string}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional gamecenter.public.v1.GameConfig game_config = 3;
 * @return {?proto.gamecenter.public.v1.GameConfig}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.getGameConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GameConfig} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameConfig, 3));
};


/**
 * @param {?proto.gamecenter.public.v1.GameConfig|undefined} value
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
*/
proto.gamecenter.private.UpsertGameConfigRequest.prototype.setGameConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.clearGameConfig = function() {
  return this.setGameConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.hasGameConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.FieldMask mask = 4;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 4));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
*/
proto.gamecenter.private.UpsertGameConfigRequest.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.UpsertGameConfigRequest} returns this
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.UpsertGameConfigRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.UpsertGameConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.UpsertGameConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.UpsertGameConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameConfig: (f = msg.getGameConfig()) && pub$gamecenter_public_v1_resource_pb.GameConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.UpsertGameConfigResponse}
 */
proto.gamecenter.private.UpsertGameConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.UpsertGameConfigResponse;
  return proto.gamecenter.private.UpsertGameConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.UpsertGameConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.UpsertGameConfigResponse}
 */
proto.gamecenter.private.UpsertGameConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pub$gamecenter_public_v1_resource_pb.GameConfig;
      reader.readMessage(value,pub$gamecenter_public_v1_resource_pb.GameConfig.deserializeBinaryFromReader);
      msg.setGameConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.UpsertGameConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.UpsertGameConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.UpsertGameConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.UpsertGameConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pub$gamecenter_public_v1_resource_pb.GameConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional gamecenter.public.v1.GameConfig game_config = 1;
 * @return {?proto.gamecenter.public.v1.GameConfig}
 */
proto.gamecenter.private.UpsertGameConfigResponse.prototype.getGameConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GameConfig} */ (
    jspb.Message.getWrapperField(this, pub$gamecenter_public_v1_resource_pb.GameConfig, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.GameConfig|undefined} value
 * @return {!proto.gamecenter.private.UpsertGameConfigResponse} returns this
*/
proto.gamecenter.private.UpsertGameConfigResponse.prototype.setGameConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.private.UpsertGameConfigResponse} returns this
 */
proto.gamecenter.private.UpsertGameConfigResponse.prototype.clearGameConfig = function() {
  return this.setGameConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.private.UpsertGameConfigResponse.prototype.hasGameConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.DeleteGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.DeleteGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.DeleteGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.DeleteGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.DeleteGameRequest}
 */
proto.gamecenter.private.DeleteGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.DeleteGameRequest;
  return proto.gamecenter.private.DeleteGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.DeleteGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.DeleteGameRequest}
 */
proto.gamecenter.private.DeleteGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.DeleteGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.DeleteGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.DeleteGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.DeleteGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.gamecenter.private.DeleteGameRequest.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.private.DeleteGameRequest} returns this
 */
proto.gamecenter.private.DeleteGameRequest.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.private.DeleteGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.private.DeleteGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.private.DeleteGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.DeleteGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.private.DeleteGameResponse}
 */
proto.gamecenter.private.DeleteGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.private.DeleteGameResponse;
  return proto.gamecenter.private.DeleteGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.private.DeleteGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.private.DeleteGameResponse}
 */
proto.gamecenter.private.DeleteGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.private.DeleteGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.private.DeleteGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.private.DeleteGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.private.DeleteGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.gamecenter.private);
