import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5e90a5f3&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=5e90a5f3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@2.1.1_webpack@5.93.0__hogan.js@3.0.2_lodash@4.17.21_prettier@3._3ct3wtnhchyxcvu4fgiutmb36y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e90a5f3",
  null
  
)

export default component.exports