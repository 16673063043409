const Management = () =>
  import(
    /* webpackChunkName: "component_management" */ 'client/components/Games/Management/Management'
  )
const Upload = () =>
  import(/* webpackChunkName: "component_build_upload" */ 'client/components/Games/Build/Upload')
const Download = () =>
  import(
    /* webpackChunkName: "component_build_download" */ 'client/components/Games/Build/Download'
  )
const ServerBuildSubmit = () =>
  import(
    /* webpackChunkName: "component_server_build_submit" */ 'client/components/Games/Server/ServerBuildSubmit'
  )
const DatabaseSetting = () =>
  import(
    /* webpackChunkName: "component_database_setting" */ 'client/components/Games/ServerFarmManagement/DatabaseSetting/Main'
  )
const DatabaseSettingList = () =>
  import(
    /* webpackChunkName: "component_database_setting" */ 'client/components/Games/ServerFarmManagement/DatabaseSetting/List'
  )
const DatabaseSettingNew = () =>
  import(
    /* webpackChunkName: "component_database_setting" */ 'client/components/Games/ServerFarmManagement/DatabaseSetting/New'
  )
const DatabaseSettingDetail = () =>
  import(
    /* webpackChunkName: "component_database_setting" */ 'client/components/Games/ServerFarmManagement/DatabaseSetting/Detail'
  )
const ServerFarmManagement = () =>
  import(
    /* webpackChunkName: "component_server_farm_management" */ 'client/components/Games/ServerFarmManagement/ServerFarmManagement'
  )
const ResourcesForDev = () =>
  import(
    /* webpackChunkName: "component_resources_for_dev" */ 'client/components/Games/Resources/ResourcesForDev'
  )
const ResourcesForDist = () =>
  import(
    /* webpackChunkName: "component_resources_for_dist" */ 'client/components/Games/Resources/ResourcesForDist'
  )
const ResourceSet = () =>
  import(
    /* webpackChunkName: "component_resource_set" */ 'client/components/Games/Resources/ResourceSet'
  )
const Releases = () =>
  import(/* webpackChunkName: "component_releases" */ 'client/components/Games/Release/Pipeline')
const ReleasePipeline = () =>
  import(
    /* webpackChunkName: "component_release_pipeline" */ 'client/components/Games/Release/PipelineCard'
  )
const Endpoints = () =>
  import(/* webpackChunkName: "component_endpoints" */ 'client/components/Games/Endpoint/Endpoints')
import { DISTRIBUTION } from 'client/components/Layout/MenuGroups/consts'

const routes = [
  {
    path: '/games/:gameCode/management',
    component: Management,
    name: 'management',
    meta: {
      title: 'home.label.gameinfo',
      group: DISTRIBUTION,
      guideDocumentCode: 'devplay-console-game-env',
      purpose: 'home.text.gameinfo.desc',
    },
  },
  {
    path: '/games/:gameCode/upload',
    component: Upload,
    name: 'client-upload',
    meta: {
      title: 'home.label.clientbuildsubmit',
      group: DISTRIBUTION,
      guideDocumentCode: 'client-build-submission-guide',
    },
  },
  {
    path: '/games/:gameCode/download',
    component: Download,
    name: 'client-download',
    meta: {
      title: 'home.label.clientbuilddownload',
      group: DISTRIBUTION,
      guideDocumentCode: 'client-build-submission-guide',
    },
  },
  {
    path: '/games/:gameCode/server-upload',
    component: ServerBuildSubmit,
    name: 'server-build-submit',
    meta: {
      title: 'home.label.serverbuildsubmit',
      group: DISTRIBUTION,
      guideDocumentCode: 'server_build_submit',
      purpose: 'home.text.serverbuilddisc',
    },
  },
  {
    path: '/games/:gameCode/database-settings',
    component: DatabaseSetting,
    children: [
      {
        path: '',
        component: DatabaseSettingList,
        name: 'server-database-setting',
        meta: {
          title: 'home.label.dbsetup',
          group: DISTRIBUTION,
        },
      },
      {
        path: 'new',
        component: DatabaseSettingNew,
        name: 'server-database-setting-new',
        meta: {
          title: 'home.label.dbsetup',
          group: DISTRIBUTION,
        },
      },
      {
        path: ':databaseSettingId',
        component: DatabaseSettingDetail,
        name: 'server-database-setting-detail',
        meta: {
          title: 'home.label.dbsetup',
          group: DISTRIBUTION,
        },
      },
    ],
  },
  {
    path: '/games/:gameCode/serverfarm-for-dev',
    redirect: '/games/:gameCode/serverfarms',
  },
  {
    path: '/games/:gameCode/serverfarm-for-qa',
    redirect: '/games/:gameCode/serverfarms',
  },
  {
    path: '/games/:gameCode/serverfarms',
    component: ServerFarmManagement,
    name: 'serverfarms',
    meta: {
      title: 'home.label.serverfarmmanage',
      group: DISTRIBUTION,
      guideDocumentCode: 'server_build_submit',
      purpose: 'home.text.servermanagedisc',
    },
  },
  {
    path: '/games/:gameCode/resources-for-dev',
    component: ResourcesForDev,
    name: 'resources-for-dev',
    meta: {
      title: 'home.label.cdnresourceupload',
      group: DISTRIBUTION,
      guideDocumentCode: 'resource_management',
    },
  },
  {
    path: '/games/:gameCode/resources-for-dist',
    component: ResourcesForDist,
    name: 'resources-for-dist',
    meta: {
      title: 'home.label.cdnresourcedist',
      group: DISTRIBUTION,
    },
  },
  {
    path: '/games/:gameCode/resource-set/:resourceSetName?',
    component: ResourceSet,
    name: 'resource-set',
    meta: {
      title: 'home.label.cdnresourceset',
      group: DISTRIBUTION,
    },
  },
  {
    path: '/games/:gameCode/release-pipeline',
    redirect: '/games/:gameCode/releases',
  },
  {
    path: '/games/:gameCode/releases',
    component: Releases,
    name: 'releases',
    meta: {
      title: 'home.label.releasemanage',
      group: DISTRIBUTION,
    },
  },
  {
    path: '/games/:gameCode/release-pipeline/:releaseId',
    redirect: '/games/:gameCode/releases/:releaseId',
  },
  {
    path: '/games/:gameCode/releases/:releaseId',
    component: ReleasePipeline,
    name: 'release-pipeline',
    meta: {
      title: 'home.label.releasemanage',
      group: DISTRIBUTION,
    },
  },
  {
    path: '/games/:gameCode/endpoints',
    component: Endpoints,
    name: 'endpoints',
    meta: {
      title: 'home.label.endpoint',
      group: DISTRIBUTION,
      guideDocumentCode: 'endpoint',
    },
  },
]

export default routes
