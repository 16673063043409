import { GameCenterConsolePromiseClient } from './grpc/pub-gamecenter/private/console_grpc_web_pb'
import {
  GetGameBasicInfoRequest,
  GetGameConfigRequest,
  UpsertGameBasicInfoRequest,
  UpsertGameConfigRequest,
} from './grpc/pub-gamecenter/private/console_pb'
import * as res from './grpc/pub-gamecenter/public/v1/resource_pb'
import { getAccessToken } from '../misc/token'
import config from '../config'

let client = null

function getClient() {
  if (client === null) {
    client = new GameCenterConsolePromiseClient(config.gamecenterEndpoint)
  }
  return client
}

export async function getGameBasicInfo(gameCode) {
  const req = new GetGameBasicInfoRequest()
  const token = getAccessToken()
  req.setGameCode(gameCode)

  return (await getClient().getGameBasicInfo(req, { Authorization: `Bearer ${token}` })).getGame()
}

export async function upsertGameBasicInfo(id, name, gameCode, publicRelease) {
  const req = new UpsertGameBasicInfoRequest()
  const info = new res.GameBasicInfo()
  const token = getAccessToken()

  info.setGameId(id)
  info.setName(name)
  info.setGameCode(gameCode)
  info.setPublicRelease(publicRelease)

  req.setGame(info)

  return await getClient().upsertGameBasicInfo(req, { Authorization: `Bearer ${token}` })
}

export async function getGameConfig(gameCode, env) {
  const req = new GetGameConfigRequest()
  const token = getAccessToken()
  req.setGameCode(gameCode)
  req.setEnv(env)

  return (
    await getClient().getGameConfig(req, {
      Authorization: `Bearer ${token}`,
    })
  ).getGameConfig()
}

export async function upsertGameConfig(
  gameCode,
  env,
  appstoreBundleId,
  playstoreBundleId,
  onestoreBundleId,
  galaxystoreBundleId,
  steamBundleId,
  playstationBundleId,
  googleClientId,
  googleClientSecret,
  facebookAppId,
  facebookAppSecret,
  onestoreClientSecret,
  galaxystoreClientSecret,
  appstoreAppSpecificSharedSecret,
  appstorePrivateKey,
  appstoreKeyId,
  appstoreIssuerId,
  googleServiceAccountJson,
  iabPub,
  onestoreIapPub,
  appleClientId,
  appleServiceId,
  playstoreDownloadUrl,
  appstoreDownloadUrl,
  onestoreDownloadUrl,
  galaxystoreDownloadUrl,
  heartbeatInterval,
  steamAppId,
  steamWebApiKey,
) {
  const req = new UpsertGameConfigRequest()
  const cfg = new res.GameConfig()
  const token = getAccessToken()

  req.setGameCode(gameCode)
  req.setEnv(env)

  const appid = new res.AppIDConfig()

  appid.setAppstoreBundleId(appstoreBundleId)
  appid.setPlaystoreBundleId(playstoreBundleId)
  appid.setOnestoreBundleId(onestoreBundleId)
  appid.setGalaxystoreBundleId(galaxystoreBundleId)
  appid.setSteamBundleId(steamBundleId)
  appid.setPlaystationBundleId(playstationBundleId)
  cfg.setAppIdConfig(appid)

  const google = new res.GoogleIDPConfig()
  google.setClientId(googleClientId)
  google.setClientSecret(googleClientSecret)
  cfg.setGoogleIdpConfig(google)

  const facebook = new res.FacebookIDPConfig()
  facebook.setAppId(facebookAppId)
  facebook.setAppSecret(facebookAppSecret)
  cfg.setFacebookIdpConfig(facebook)

  const appstore = new res.AppstoreConfig()
  appstore.setAppSpecificSharedSecret(appstoreAppSpecificSharedSecret)
  appstore.setPrivateKey(appstorePrivateKey)
  appstore.setKeyId(appstoreKeyId)
  appstore.setIssuerId(appstoreIssuerId)
  cfg.setAppstoreConfig(appstore)

  const playstore = new res.PlaystoreConfig()
  playstore.setServiceAccountJson(googleServiceAccountJson)
  playstore.setIabPubKey(iabPub)
  cfg.setPlaystoreConfig(playstore)

  const onestore = new res.OnestoreConfig()
  onestore.setIapPubKey(onestoreIapPub)
  onestore.setClientSecret(onestoreClientSecret)
  cfg.setOnestoreConfig(onestore)

  const galaxystore = new res.GalaxystoreConfig()
  galaxystore.setClientSecret(galaxystoreClientSecret)
  cfg.setGalaxystoreConfig(galaxystore)

  const apple = new res.AppleIDPConfig()
  apple.setClientId(appleClientId)
  apple.setServiceId(appleServiceId)
  cfg.setAppleIdpConfig(apple)

  const clientSDK = new res.ClientSDKConfig()
  clientSDK.setPlaystoreDownloadUrl(playstoreDownloadUrl)
  clientSDK.setAppstoreDownloadUrl(appstoreDownloadUrl)
  clientSDK.setOnestoreDownloadUrl(onestoreDownloadUrl)
  clientSDK.setGalaxystoreDownloadUrl(galaxystoreDownloadUrl)
  clientSDK.setHeartbeatInterval(heartbeatInterval)
  cfg.setClientSdkConfig(clientSDK)

  const steamConfig = new res.SteamConfig()
  steamConfig.setAppId(steamAppId)
  steamConfig.setWebApiKey(steamWebApiKey)
  cfg.setSteamConfig(steamConfig)

  req.setGameConfig(cfg)

  return await getClient().upsertGameConfig(req, { Authorization: `Bearer ${token}` })
}
