const NotFound = () =>
  import(/* webpackChunkName: "component_not_found" */ 'client/components/NotFound')
const GameHome = () =>
  import(/* webpackChunkName: "component_game_home" */ 'client/components/Games/GameHome')
const UserAccount = () =>
  import(/* webpackChunkName: "components_account" */ 'client/components/User/Account')

import distributionRoutes from 'client/routes/distribution'
import inqueryRoutes from 'client/routes/inquery'
import operationRoutes from 'client/routes/operation'
import documentsRoutes from 'client/routes/documents'

const routes = [
  {
    path: '/',
    component: GameHome,
    name: 'home',
    meta: {
      title: 'home.label.home',
    },
  },
  {
    path: '/games',
    redirect: '/',
  },
  {
    path: '/games/:gameCode',
    component: GameHome,
    name: 'game-home',
    meta: {
      title: 'home.label.home',
    },
  },
  {
    path: '/me',
    component: UserAccount,
    name: 'me',
    meta: {
      title: 'home.label.myaccount',
    },
  },
  ...distributionRoutes,
  ...inqueryRoutes,
  ...operationRoutes,
  ...documentsRoutes,
  {
    path: '/games/:gameCode/*',
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },
]

export default routes
